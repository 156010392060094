/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldsError } from '~/Api/FieldsError';
import { MetaDataResponseType } from '~/Api/types';
import { PermissionsType } from '~/constants/permissions';
import { ADMIN_ROLES, ADMIN_STATUSES } from '~/modules/admins/constants';
import { AdminType } from '~/modules/admins/types';
import { ProductType, REGULARITY } from '~/modules/products/types';
import { ExtendedUserType, UserType } from '~/modules/users/types';
import AbstractStorage from '~/services/storage/AbstractStorage';
import localStorage from '~/services/storage/localStorage';
import { SORT_ORDER } from '~/types/common';
import { ProfileType } from '~/types/profile';

import {
  Api,
  HandlersUpdateAdminRequest,
  RequestParams,
} from './generated-api/api';

export const BASE_API_URL = process.env.REST_API_BASE_URL || '/';

const baseApiClient = new Api({
  baseUrl: BASE_API_URL + '/admin',
});

class AdminApi {
  static ACCESS_TOKEN_STORAGE_KEY = 'access_token';
  static REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

  accessToken: string | null;
  refreshToken: string | null;
  storage: AbstractStorage;

  constructor({
    accessToken,
    refreshToken,
    storage,
  }: {
    accessToken: string | null;
    refreshToken: string | null;
    storage: AbstractStorage;
  }) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    this.storage = storage;
  }

  static async handleError(e: any, methodName): Promise<Error> {
    try {
      if (e.status === 404) {
        if (e.error?.error) {
          throw Error(e.error.error);
        }

        throw Error('Not found');
      }

      if (e.status >= 500) {
        throw Error('Server error');
      }

      if (e?.error?.fields) {
        return new FieldsError(e.error);
      }

      if (e?.error?.error) {
        return new Error(e.error.error);
      }

      if (e instanceof Response) {
        const parsedData = await e.json();

        if (parsedData.fields) {
          return new FieldsError(parsedData);
        }

        if (parsedData.error) {
          return new Error(parsedData.error);
        }
      }

      throw Error('Error');
    } catch (e: any) {
      return new Error(e.message || `Error in ${methodName}`);
    }
  }

  private getAuthHeaders(): Record<string, string> {
    if (this.accessToken === null) {
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
    return {
      'Access-Token': this.accessToken,
    };
  }

  private setAccessKeys(access_token: string, refresh_token: string): string {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.storage.setByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY, access_token);
    this.storage.setByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY, refresh_token);
    return access_token;
  }

  private clearAccessKeys(): void {
    this.accessToken = null;
    this.refreshToken = null;
    this.storage.removeByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
    this.storage.removeByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);
  }

  private async makeAuthorizedRequest<T>(
    requestFunction: (params: RequestParams) => Promise<T>,
    methodName: string,
  ): Promise<T> {
    try {
      try {
        return await requestFunction({ headers: this.getAuthHeaders() });
      } catch (e: any) {
        if (e?.status === 403 || e?.status === 401) {
          try {
            await this.updateTokens();
          } catch (e: any) {
            if (e.message === 'Not authorized') {
              await this.clearAccessKeys();
              window.location.reload();
            }
          }

          return await requestFunction({ headers: this.getAuthHeaders() });
        }

        throw e;
      }
    } catch (e) {
      throw await AdminApi.handleError(e, methodName);
    }
  }

  static mapProfileResponse = (profile: Record<string, any>): ProfileType => {
    try {
      return {
        id: profile.id || '',
        email: profile.email || '',
        status: profile.status || ADMIN_STATUSES.ACTIVE,
        role: profile.role || ADMIN_ROLES.SUPPORT_AGENT,
        permissions: profile.permissions || ({} as PermissionsType),
        created_at: profile.created_at || '2021-01-01',
        updated_at: profile.updated_at || '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapAdminResponse = (admin: Record<string, any>): AdminType => {
    try {
      return {
        id: admin.id || '',
        email: admin.email || '',
        status: admin.status || ADMIN_STATUSES.ACTIVE,
        role: admin.role || ADMIN_ROLES.SUPPORT_AGENT,
        permissions: admin.permissions || {},
        created_at: admin.created_at || '2021-01-01',
        updated_at: admin.updated_at || '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapUserResponse = (user: Record<string, any>): UserType => {
    try {
      return {
        id: user.id ?? '',
        email: user.email ?? '',
        name: user.name ?? '',
        detectors_extra_words_left: user.detectors_extra_words_left ?? 0,
        response_count_total: user.response_count_total ?? 0,
        is_onboarded: user.is_onboarded ?? false,
        is_stripe_user: user.is_stripe_user ?? false,
        is_chrome_extension_installed:
          user.is_chrome_extension_installed ?? false,
        created_at: user.created_at ?? '2021-01-01',
        updated_at: user.updated_at ?? '2021-01-01',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapExtendedUserResponse = (
    user: Record<string, any>,
  ): ExtendedUserType => {
    try {
      return {
        id: user.id ?? '',
        email: user.email ?? '',
        name: user.name ?? '',
        avatar: user.avatar_url ?? '',
        detectors_extra_words_left: user.detectors_extra_words_left ?? 0,
        response_count_total: user.response_count_total ?? 0,
        is_onboarded: user.is_onboarded ?? false,
        is_stripe_user: user.is_stripe_user ?? false,
        is_chrome_extension_installed:
          user.is_chrome_extension_installed ?? false,
        created_at: user.created_at ?? '2021-01-01',
        updated_at: user.updated_at ?? '2021-01-01',
        extra_fields: {
          ab_tests: user.extra_fields?.ab_tests ?? {},
          notifications: user.extra_fields?.notifications ?? {},
          highlights: user.extra_fields?.highlights ?? {},
          test_form: user.extra_fields?.test_form ?? {},
          sign_up_at: user.extra_fields?.sign_up_at ?? null,
          feedbackV2: user.extra_fields?.feedbackV2 ?? {},
          pwa_modal: user.extra_fields?.pwa_modal ?? {},
          cancel_sub_reasons: user.extra_fields?.cancel_sub_reasons ?? {},
          rewards: user.extra_fields?.rewards ?? {},
        },
        user_features: user.user_features ?? {},
        user_product: user.user_product ?? {},
        user_subscription: user.user_subscription ?? {},
        user_onboarding: user.user_onboarding ?? {},
        backend_ab_tests: user.backend_ab_tests ?? {},
        orders: user.orders ?? null,
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  static mapProductResponse = (product: Record<string, any>): ProductType => {
    try {
      return {
        id: product.id ?? '',
        is_unlimited: product.is_unlimited ?? false,
        currency: product.currency ?? 'USD',
        alternative_non_trial_name: product.alternative_non_trial_name ?? '',
        has_trial_period: product.has_trial_period ?? false,
        solid_name_for_bi: product.solid_name_for_bi ?? '',
        is_solid: product.is_solid ?? false,
        name: product.name ?? '',
        price: product.price ?? 0,
        trial_amount: product.trial_amount ?? 0,
        trial_period: product.trial_period ?? 0,
        trial_words_amount: product.trial_words_amount ?? 0,
        words_amount: product.words_amount ?? 0,
        regularity: product.regularity ?? REGULARITY.ONETIME,
        billing_period: product.billing_period ?? undefined,
        new_price_ui: product.new_price_ui ?? 0,
        old_price_ui: product.old_price_ui ?? 0,
        discount_percentage_ui: product.discount_percentage_ui ?? 0,
        regularity_ui: product.regularity_ui ?? REGULARITY.ONETIME,
        description: product.description ?? '',
        description_ui: product.description_ui ?? '',
        details_ui: product.details_ui ?? '',
        is_popular_ui: product.is_popular_ui ?? false,
        bi_is_upsell: product.bi_is_upsell ?? false,
        tags: product.tags ?? [],
        updated_at: product.updated_at ?? '2021-01-01T00:00:00Z',
        created_at: product.created_at ?? '2021-01-01T00:00:00Z',
      };
    } catch (e) {
      console.error(e);
      throw Error('Invalid response');
    }
  };

  async updateTokens(): Promise<void> {
    try {
      if (this.refreshToken && this.accessToken) {
        const { data } = await baseApiClient.api.v1AuthRefreshTokenCreate({
          refresh_token: this.refreshToken,
        });

        if (data.data?.access_token && data.data?.refresh_token) {
          const { access_token, refresh_token } = data.data;
          this.setAccessKeys(access_token, refresh_token);
        }
      }
    } catch (e) {
      console.error(e);
      this.clearAccessKeys();
      throw Error('Not authorized');
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      const { data } = await baseApiClient.api.v1AuthLoginCreate({
        email: email.trim(),
        password,
      });

      if (data.data?.access_token && data.data?.refresh_token) {
        const { access_token, refresh_token } = data.data;
        this.setAccessKeys(access_token, refresh_token);
        return;
      }
    } catch (error) {
      throw await AdminApi.handleError(error, 'signIn');
    }
  }

  // NOT IMPLEMENTED YET
  async signUp(params: {
    email: string;
    password: string;
    name: string;
  }): Promise<void> {
    try {
      // await baseApiClient.adminPanel.signUpCreate({
      //   ...params,
      //   email: params.email.trim(),
      // });
      // const { data } = await baseApiClient.api.v1AuthSignUpCreate({
      //   ...params,
      //   email: params.email.trim(),
      // });
    } catch (error) {
      throw await AdminApi.handleError(error, 'signUp');
    }
  }

  // NOT IMPLEMENTED YET
  async forgotPassword(email: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.forgotPasswordCreate({
    //     email: email.trim(),
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'forgotPassword');
    // }
  }

  // NOT IMPLEMENTED YET
  async resetPassword(token: string, password: string): Promise<void> {
    // try {
    //   await baseApiClient.adminPanel.resetPasswordCreate({
    //     token,
    //     password,
    //   });
    // } catch (error) {
    //   throw await AdminApi.handleError(error, 'resetPassword');
    // }
  }

  async signOut(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      try {
        // await baseApiClient.adminPanel.logoutCreate(params);
        // await baseApiClient.api.v1AuthLogoutCreate(params);
        this.clearAccessKeys();
      } catch (e) {
        throw await AdminApi.handleError(e, 'signOut');
      }
    }, 'signOut');
  }

  async getProfile(): Promise<ProfileType> {
    return this.makeAuthorizedRequest<ProfileType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProfileList(params);

      if (data.data) {
        return AdminApi.mapProfileResponse(data.data);
      }
      throw Error('Invalid response');
    }, 'getProfile');
  }

  async createAdmin(
    role: ADMIN_ROLES, // TODO! Add a chance to add super_admin as well
    email: string,
    password: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1AdminsCreate(
        { role: ADMIN_ROLES.SUPPORT_AGENT, email, password },
        params,
      );
    }, 'createAdmin');
  }

  async updateAdminPassword(id: string, password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      // await baseApiClient.adminPanel.adminsInviteCreate({ email }, params); // TODO: uncomment when BE will be ready
    }, 'updateAdminPassword');
  }

  async getAdmin(id: string): Promise<AdminType> {
    return this.makeAuthorizedRequest<AdminType>(async (params) => {
      const { data } = await baseApiClient.api.v1AdminsDetail(id, params);

      if (data.data) {
        return AdminApi.mapAdminResponse(data.data);
      }

      throw Error('Invalid response');
    }, 'getAdmin');
  }

  async updateAdmin(
    id: string,
    dataToUpdate: Partial<AdminType>,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const body = {
        role: dataToUpdate.role,
        status: dataToUpdate.status,
      } as HandlersUpdateAdminRequest;
      await baseApiClient.api.v1AdminsPartialUpdate(id, body, params);
    }, 'updateAdmin');
  }

  async deleteAdmin(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1AdminsDelete(id, params);
    }, 'deleteAdmin');
  }

  async getAdminsList({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      per_page: number;
      search_text: string;
    };
  }): Promise<{
    data: AdminType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AdminType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1AdminsList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const admins = data.data;
      const meta = data.meta;

      return {
        data: admins.map((item) => AdminApi.mapAdminResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getAdminsList');
  }

  async getProduct(id: string): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsDetail(id, params);

      if (data.data) {
        return AdminApi.mapProductResponse(data.data);
      }

      throw Error('Invalid response in getProduct');
    }, 'getProduct');
  }

  async getProductsList({
    query,
  }: {
    query: {
      search_text?: string;
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      per_page: number;
    };
  }): Promise<{
    data: ProductType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ProductType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const products = data.data;
      const meta = data.meta;

      return {
        data: products.map((item) => AdminApi.mapProductResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getProductsList');
  }

  // NOT IMPLEMENTED YET
  async getModifiedProductsById(
    id: string,
  ): Promise<{ downgraded: ProductType[]; upgraded: ProductType[] }> {
    return this.makeAuthorizedRequest<{
      downgraded: ProductType[];
      upgraded: ProductType[];
      // @ts-ignore
    }>(async (params) => {
      // const { data } = await baseApiClient.adminPanel.productsDetail(
      //   id,
      //   params,
      // );
      // const data = {
      //   downgraded: [AdminApi.mapProductResponse(mockProducts[0])],
      //   upgraded: [
      //     AdminApi.mapProductResponse(mockProducts[mockProducts.length - 1]),
      //   ],
      // };

      return [];
      // return AdminApi.mapUserResponse(data.data);
    }, 'getModifiedProductsById');
  }

  async updateProduct(formData: ProductType): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsUpdate(
        formData,
        params,
      );

      if (data.data) {
        return AdminApi.mapProductResponse(data.data);
      }

      throw Error('Invalid response in updateProduct');
    }, 'updateProduct');
  }

  async createProduct(formData: ProductType): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ProductsCreate(formData, params);
    }, 'createProduct');
  }

  async deleteProduct(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ProductsDelete(id, params);
    }, 'deleteProduct');
  }

  async getUser(id: string): Promise<ExtendedUserType> {
    return this.makeAuthorizedRequest<ExtendedUserType>(async (params) => {
      const { data } = await baseApiClient.api.v1UsersDetail(id, params);

      return AdminApi.mapExtendedUserResponse(data.data);
    }, 'getUser');
  }

  async getUsersList({
    query,
  }: {
    query: {
      sort_type: SORT_ORDER;
      sort_by: 'created_at' | 'updated_at';
      page: number;
      per_page: number;
      search_text: string;
    };
  }): Promise<{
    data: UserType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: UserType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1UsersList(query, params);

      if (!data.data || !data.meta) {
        return {
          data: [],
          meta: {
            currentPage: 1,
            pageSize: 20,
            totalCount: 0,
            totalPages: 1,
          },
        };
      }

      const users = data.data;
      const meta = data.meta;

      return {
        data: users.map((item) => AdminApi.mapUserResponse(item)),
        meta: {
          currentPage: meta.current_page ?? 1,
          pageSize: meta.page_size ?? 10,
          totalCount: meta.total_count ?? 0,
          totalPages: meta.total_pages ?? 1,
        },
      };
    }, 'getUsersList');
  }

  async deleteUser(id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1UsersDelete(id, params);
    }, 'deleteUser');
  }

  async updateUserExtraWords(id: string, words: number): Promise<number> {
    return this.makeAuthorizedRequest<number>(async (params) => {
      const { data } =
        await baseApiClient.api.v1UsersDetectorsExtraWordsPartialUpdate(
          id,
          { words },
          params,
        );

      if (data.data !== undefined && data.data !== null) {
        return data.data;
      }

      throw Error('Invalid response in updateUserExtraWords');
    }, 'updateUserExtraWords');
  }

  async updateUserPassword(id: string): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const { data } = await baseApiClient.api.v1UsersPasswordPartialUpdate(
        id,
        params,
      );

      if (data.data) {
        return data.data;
      }

      throw Error('Invalid response in updateUserPassword');
    }, 'updateUserPassword');
  }

  async updateUserEmail(id: string, email: string): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const { data } = await baseApiClient.api.v1UsersEmailPartialUpdate(
        id,
        { email },
        params,
      );

      if (data.data) {
        return data.data;
      }

      throw Error('Invalid response in updateUserEmail');
    }, 'updateUserEmail');
  }
}

const accessToken = localStorage.getByKey(AdminApi.ACCESS_TOKEN_STORAGE_KEY);
const refreshToken = localStorage.getByKey(AdminApi.REFRESH_TOKEN_STORAGE_KEY);

export default new AdminApi({
  accessToken,
  refreshToken,
  storage: localStorage,
});

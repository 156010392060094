export enum REGULARITY {
  ONETIME = 'onetime',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type ProductType = {
  id: string;
  name: string;
  is_solid: boolean;
  has_trial_period: boolean;
  regularity: REGULARITY;
  price: number;
  currency: string;
  billing_period: number;
  trial_amount: number;
  trial_period: number;
  description: string;
  words_amount: number;
  trial_words_amount: number;
  discount_percentage_ui: number;
  details_ui: string;
  is_popular_ui: boolean;
  is_unlimited: boolean;
  new_price_ui: number;
  old_price_ui: number;
  description_ui: string;
  regularity_ui: REGULARITY;
  solid_name_for_bi: string;
  alternative_non_trial_name: string;
  bi_is_upsell: boolean;
  tags: string[];
  created_at: string;
  updated_at: string;
};

import React, { FC } from 'react';

import {
  TextField as MuiTextField,
  styled,
  TextFieldProps,
} from '@mui/material';

import { colors } from '~/theme/colors';

export type NumberFieldCustomProps = TextFieldProps;

export const StyledTextField = styled(MuiTextField, {
  name: 'StyledTextField',
})(() => ({
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    backgroundColor: colors.white,

    '&.MuiInputBase-colorWarning.Mui-focused + .MuiFormHelperText-root': {
      color: colors.warning_50,
    },

    '& .MuiList-root': {
      backgroundColor: colors.white,
    },
  },

  '& legend': {
    display: 'none',
  },

  '& .MuiInputLabel-root': {
    color: colors.neutral_20,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    marginBottom: '8px',
    position: 'static',
    transform: 'unset',

    '.MuiFormLabel-asterisk': {
      display: 'none',
    },

    '&.Mui-error': {
      color: colors.error_40,
    },
  },

  '& .MuiInputBase-input': {
    padding: '14px 16px',
    fontSize: '14px',
    lineHeight: '20px',

    '&::placeholder': {
      color: '#8C8C90',
      opacity: 1,
    },

    '&[type=number]': {
      MozAppearance: 'textfield', // Firefox
    },

    '&[type=number]::-webkit-outer-spin-button, &[type=number]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none', // Chrome, Safari, Edge
        margin: 0,
      },
  },

  '& .MuiInputBase-inputSizeSmall': {
    padding: '10px 12px',
    fontSize: '14px',
    lineHeight: '20px',

    '&::placeholder': {
      color: '#8C8C90',
      opacity: 1,
    },
  },

  '& .MuiInputBase-multiline': {
    padding: '14px 16px',

    '& .MuiInputBase-input': {
      padding: '0px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    top: 0,
    borderColor: '#E2E2EC',

    '&.Mui-error': {
      borderColor: colors.error_40,
    },
  },

  '&.Mui-focused': {
    '& fieldset': {
      borderWidth: '2px',
    },
  },

  '& .MuiInputAdornment-root': {
    margin: 0,
  },

  '& .MuiInputBase-adornedStart': {
    padding: 0,
  },

  '& .MuiFormHelperText-root': {
    fontSize: '12px',
    marginInline: '10px',
  },
}));

const NumberField: FC<NumberFieldCustomProps> = ({
  value,
  onChange,
  ...props
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;

    if (newValue && Number(newValue) < 0) {
      return;
    }

    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault();
    }
  };

  const handleOnWheel = (event): void => event.currentTarget.blur();

  return (
    <StyledTextField
      value={value}
      inputMode="numeric"
      type="number"
      inputProps={{
        pattern: '[0-9]*',
        autoComplete: 'off',
        'aria-autocomplete': 'none',
        // prevent changing value on touchpad wheel
        onWheel: handleOnWheel,
      }}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};

export default NumberField;

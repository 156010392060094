// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eszFoCqSepVes2xsvm3h{position:relative;width:100%}.yu94vpsBOljTDVY2fUXW{position:absolute;top:4px;right:4px;z-index:2}", "",{"version":3,"sources":["webpack://./src/components/molecules/SearchBar/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,iBAAA,CACA,UAAA,CAGF,sBACE,iBAAA,CACA,OAAA,CACA,SAAA,CACA,SAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.container {\n  position: relative;\n  width: 100%;\n}\n\n.submit_button {\n  position: absolute;\n  top: 4px;\n  right: 4px;\n  z-index: 2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "eszFoCqSepVes2xsvm3h",
	"submit_button": "yu94vpsBOljTDVY2fUXW"
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

import HookFormNumberField from '~/components/hookFormControls/NumberFieldControl';
import HookFormSelectField from '~/components/hookFormControls/SelectFieldControl';
import HookFormSwitch from '~/components/hookFormControls/SwitchControl';
import HookFormTextField from '~/components/hookFormControls/TextFieldControl';
import { FORM_FIELDS } from '~/types/form';

interface InputRendererProps {
  type: FORM_FIELDS;
  props: any;
}

const InputRenderer: React.FC<InputRendererProps> = ({ type, props }) => {
  switch (type) {
    case FORM_FIELDS.NUMBER:
      return (
        <HookFormNumberField
          {...props}
          size="small"
          InputLabelProps={{ shrink: true }}
          {...(props.defaultValue ? { defaultValue: props.defaultValue } : {})}
        />
      );
    case FORM_FIELDS.TEXT:
      return (
        <HookFormTextField
          {...props}
          size="small"
          InputLabelProps={{ shrink: true }}
          {...(props.defaultValue ? { defaultValue: props.defaultValue } : {})}
        />
      );
    case FORM_FIELDS.TEXTAREA:
      return (
        <HookFormTextField
          {...props}
          multiline={true}
          minRows={4}
          maxRows={10}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      );
    case FORM_FIELDS.SELECT:
      return (
        <HookFormSelectField
          {...props}
          size="small"
          InputLabelProps={{ shrink: true }}
          {...(props.options.length
            ? { defaultValue: props.options[0].value }
            : {})}
        />
      );
    case FORM_FIELDS.TOGGLE:
      return (
        <HookFormSwitch
          {...props}
          size="medium"
          InputLabelProps={{ shrink: true }}
        />
      );
    default:
      return <></>;
  }
};

export default InputRenderer;

import React, { FC, useEffect } from 'react';

import { Icon } from '@iconify/react';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import Pagination from '~/components/molecules/Pagination';
import SearchBar from '~/components/molecules/SearchBar';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import TotalCount from '~/components/molecules/TotalCount';
import NotFound from '~/components/organisms/NotFound';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useFilters from '~/hooks/useFilters';
import useQueryParams from '~/hooks/useQueryParams';
import { ADMIN_ROLES } from '~/modules/admins/constants';
import CreateAdminForm from '~/modules/admins/views/AdminsList/CreateAdminForm';
import Table from '~/modules/admins/views/AdminsList/Table';
import useData, {
  FiltersType,
} from '~/modules/admins/views/AdminsList/useData';
import { SORT_ORDER } from '~/types/common';

import styles from './styles.modules.scss';

const DEFAULT_FILTER_STATE: FiltersType = {
  sortOrder: SORT_ORDER.DESC,
  sortBy: 'created_at',
  page: 1,
  perPage: 10,
  searchText: '',
};

const AdminsList: FC = () => {
  const { handleOpenModal } = useModalContext();
  const { showSnackbar } = useSnackBarContext();
  const { getQueryParams, setQueryParams } = useQueryParams();

  const queryParams = getQueryParams();

  const defaultFilterState = {
    ...DEFAULT_FILTER_STATE,
    ...queryParams,
  };

  const { filters, updateFilters, resetFilters } =
    useFilters<FiltersType>(defaultFilterState);

  const { admins, isLoading, meta, updateItems } = useData(filters);

  useEffect(() => {
    setQueryParams(filters);
  }, [filters]);

  useEffect(() => {
    if (Object.keys(queryParams).length === 0) {
      setQueryParams(filters);
    }
  }, [queryParams]);

  const handleCreateAdmin = async (
    role: ADMIN_ROLES,
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      await Api.createAdmin(role, email, password);
      await updateItems();
      showSnackbar('Admin created', 'success');
    } catch (e: any) {
      showSnackbar(e.message);
      console.error(e);
    }
  };

  return (
    <div className={styles.container}>
      <TopHeadlineRow text="Admins">
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={(): void => {
            handleOpenModal({
              component: ({ onClose }) => (
                <CreateAdminForm
                  onCreateAdmin={handleCreateAdmin}
                  onClose={onClose}
                />
              ),
            });
          }}
        >
          <Icon
            style={{ marginRight: 5 }}
            fontSize={20}
            icon="material-symbols:person-add-rounded"
          />
          New Admin
        </Button>
      </TopHeadlineRow>

      <Loader isLoading={isLoading} />

      <div className={styles.content}>
        <div className={styles.top_bar}>
          <div className={styles.search}>
            <SearchBar
              isDisabled={isLoading}
              placeholder="Search by ID or email"
              query={
                queryParams.searchText ? String(queryParams.searchText) : ''
              }
              setQuery={(query): void => {
                updateFilters({ searchText: query });
              }}
            />
          </div>

          <Button
            className={styles.reset_button}
            onClick={(): void => resetFilters(DEFAULT_FILTER_STATE)}
            disabled={
              isLoading ||
              JSON.stringify(filters) === JSON.stringify(DEFAULT_FILTER_STATE)
            }
          >
            <Icon icon="carbon:reset" width="18" />
            <span>Clear Filters</span>
          </Button>
        </div>

        {queryParams.searchText && !isLoading && admins.length === 0 ? (
          <NotFound
            query={queryParams.searchText ? String(queryParams.searchText) : ''}
            onReset={(): void => resetFilters(DEFAULT_FILTER_STATE)}
          />
        ) : (
          <>
            {admins.length > 0 && (
              <>
                <TotalCount count={meta.totalCount} />
                <div className={styles.table}>
                  <Table
                    admins={admins}
                    filter={{
                      sortBy: filters.sortBy,
                      sortOrder: filters.sortOrder,
                    }}
                    applyFilters={(filters): void => updateFilters(filters)}
                  />
                </div>
                {meta.totalPages > 1 && (
                  <Pagination
                    currentPage={Number(meta.currentPage)}
                    totalPages={Number(meta.totalPages)}
                    setPage={(page: number): void => {
                      updateFilters({ page });
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminsList;

export type FormDataType = Record<string, string | number | boolean | string[]>;

export enum FORM_FIELDS {
  NUMBER = 'number',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  TAGS = 'tags',
  SELECT = 'select',
  COUNTER = 'counter',
  TOGGLE = 'toggle',
  SELECT_CUSTOM_OPTION = 'select_custom_option',
  TEXTAREA_UPLOAD = 'textarea_upload',
  INSERT_LINK_META = 'insert_link_meta',
}

export type UiFieldType = {
  $ref?: { field: string; value: string | number | boolean }[];
  title: string;
  type: FORM_FIELDS;
  props: {
    placeholder?: string;
    options?: { text: string; value: string | number | boolean }[];
    required: boolean;
    disabled?: boolean;
    hidden?: boolean;
    defaultValue?: string | number | boolean;
    minValue?: number;
    maxValue?: number;
  };
  group?: string;
  max_count?: number;
};

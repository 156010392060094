import { ProductType, REGULARITY } from '~/modules/products/types';
import { FORM_FIELDS, UiFieldType } from '~/types/form';

import currencySolid from './currency_solid.json';

export const ProductsPath = '/products';
export const ProductsTitle = 'Products';
export const ProductsSidebarKey = 'Products';

export const ProductByIdPath = '/products/:id';
export const ProductNewPath = '/products/new';

export const initialProductData: ProductType = {
  is_solid: false,
  id: '',
  solid_name_for_bi: '',
  name: '',
  description: '',
  description_ui: '',
  price: 0,
  currency: 'USD',
  billing_period: 0,
  regularity: REGULARITY.ONETIME,
  has_trial_period: false,
  alternative_non_trial_name: '',
  trial_period: 0,
  trial_amount: 0,
  discount_percentage_ui: 0,
  trial_words_amount: 0,
  words_amount: 0,
  new_price_ui: 0,
  old_price_ui: 0,
  regularity_ui: REGULARITY.ONETIME,
  details_ui: '',
  bi_is_upsell: false,
  is_unlimited: false,
  is_popular_ui: false,
  tags: [],
  created_at: '2022-11-11T11:11:11Z',
  updated_at: '2022-11-11T11:11:11Z',
};

const paymentGates = [
  { text: 'Solid', value: true },
  { text: 'Stripe', value: false },
];

const currencies = currencySolid.currencies.map((cur) => {
  return {
    text: cur.isoCode,
    value: cur.isoCode,
  };
});

const regularities = [
  { text: 'Onetime', value: REGULARITY.ONETIME },
  { text: 'Day', value: REGULARITY.DAY },
  { text: 'Week', value: REGULARITY.WEEK },
  { text: 'Month', value: REGULARITY.MONTH },
  { text: 'Year', value: REGULARITY.YEAR },
];

export const PRODUCT_SCHEMA: {
  ui_schema: Record<string, UiFieldType>;
  json_schema: Record<string, any>;
} = {
  ui_schema: {
    is_solid: {
      props: {
        options: paymentGates,
        required: true,
        disabled: true,
      },
      title: 'Payment Gate (is Solid?)',
      type: FORM_FIELDS.SELECT,
    },
    id: {
      props: {
        placeholder: '11111111-cccc-111e-1111-cc111ccc1cc1',
        required: true,
      },
      title: 'Product ID (from Solid Admin Panel)',
      type: FORM_FIELDS.TEXT,
      group: 'General Information About the Subscription',
    },
    solid_name_for_bi: {
      props: {
        placeholder: 'Justdone_plan_39.99_usd_30days_MultiC',
        required: true,
      },
      title: 'Product name (from Solid Admin Panel)',
      type: FORM_FIELDS.TEXT,
    },
    name: {
      props: {
        placeholder: '7-day Access Plan',
        required: true,
      },
      title: 'Product name (for Platform Appearance)',
      type: FORM_FIELDS.TEXT,
    },
    description: {
      max_count: 64,
      props: {
        placeholder: 'Justdone.ai Monthly Subscription \n(support@justdone.ai)',
        required: true,
      },
      title: 'Description (from Solid Admin Panel)',
      type: FORM_FIELDS.TEXT,
    },
    description_ui: {
      max_count: 200,
      props: {
        placeholder: 'Unlimited Plan without any limits',
        required: true,
      },
      title: 'Description (for Platform Appearance)',
      type: FORM_FIELDS.TEXT,
    },
    currency: {
      props: {
        options: currencies,
        required: true,
      },
      title: 'Currency',
      type: FORM_FIELDS.SELECT,
    },
    has_trial_period: {
      props: { defaultValue: false, required: true },
      title: 'Is trial?',
      type: FORM_FIELDS.TOGGLE,
    },
    alternative_non_trial_name: {
      $ref: [
        {
          field: 'has_trial_period',
          value: true,
        },
      ],
      props: {
        placeholder: 'Unlimited Monthly Plan',
        required: true,
      },
      title: 'Alternative non-trial product name (for Platform Appearance)',
      type: FORM_FIELDS.TEXT,
      group: 'Information About Trial',
    },
    trial_period: {
      $ref: [
        {
          field: 'has_trial_period',
          value: true,
        },
      ],
      props: {
        placeholder: '30',
        required: true,
      },
      title: 'Trial duration (days)',
      type: FORM_FIELDS.NUMBER,
    },
    trial_amount: {
      $ref: [
        {
          field: 'has_trial_period',
          value: true,
        },
      ],
      props: {
        placeholder: '100',
        required: true,
      },
      title: 'Trial price (cents)',
      type: FORM_FIELDS.NUMBER,
    },
    regularity: {
      props: {
        options: regularities,
        required: true,
      },
      title: 'Regularity',
      type: FORM_FIELDS.SELECT,
      group: 'Subscription Details',
    },
    billing_period: {
      props: {
        placeholder: '30',
        required: true,
      },
      title: 'Billing period (days)',
      type: FORM_FIELDS.NUMBER,
    },
    price: {
      props: {
        placeholder: '3999',
        required: true,
      },
      title: 'Price (cents)',
      type: FORM_FIELDS.NUMBER,
    },
  },
  json_schema: {
    $schema: 'http://json-schema.org/draft-04/schema#',
    properties: {
      is_solid: {
        enum: paymentGates.map((el) => el.value),
        type: 'boolean',
      },
      id: {
        maxLength: 50,
        type: 'string',
        pattern:
          '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
      },
      solid_name_for_bi: {
        maxLength: 200,
        type: 'string',
      },
      name: {
        maxLength: 200,
        type: 'string',
      },
      description: {
        maxLength: 64,
        type: 'string',
      },
      currency: {
        enum: currencies.map((el) => el.value),
        type: 'string',
      },
      has_trial_period: {
        type: 'boolean',
      },
      regularity: {
        enum: regularities.map((el) => el.value),
        type: 'string',
      },
      billing_period: {
        type: 'number',
      },
      price: {
        type: 'number',
      },
    },
    allOf: [
      {
        if: {
          properties: {
            has_trial_period: {
              const: true,
              type: 'boolean',
            },
          },
          required: ['has_trial_period'],
        },
        then: {
          type: 'object',
          properties: {
            alternative_non_trial_name: {
              type: 'string',
            },
            trial_period: {
              type: 'number',
            },
            trial_amount: {
              type: 'number',
            },
          },
          required: [
            'alternative_non_trial_name',
            'trial_period',
            'trial_amount',
          ],
        },
      },
    ],
    required: [
      'is_solid',
      'id',
      'solid_name_for_bi',
      'name',
      'description',
      'currency',
      'has_trial_period',
      'regularity',
      'billing_period',
      'price',
    ],
    type: 'object',
  },
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lJ_Fj_pDcHuYeznTyyKG{display:flex;flex-wrap:nowrap;height:100vh;overflow:hidden}.huB768xyH3TpYbIz3KXR{min-width:206px;border-right:1px solid #aeafb1;height:100%}.TA0gxRj4hpKrcJFx4C_3{width:100%;height:100%;overflow-y:scroll;position:relative;-ms-overflow-style:none;scrollbar-width:none}.TA0gxRj4hpKrcJFx4C_3::-webkit-scrollbar{display:none}", "",{"version":3,"sources":["webpack://./src/components/layoutComponents/SidebarLayout/Desktop/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,gBAAA,CACA,YAAA,CACA,eAAA,CAEA,sBACE,eAAA,CACA,8BAAA,CACA,WAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAEA,uBAAA,CACA,oBAAA,CAEA,yCACE,YAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.desktop_layout {\n  display: flex;\n  flex-wrap: nowrap;\n  height: 100vh;\n  overflow: hidden;\n\n  &__sidebar {\n    min-width: 206px;\n    border-right: 1px solid $neutral-40;\n    height: 100%;\n  }\n\n  &__main {\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    position: relative;\n\n    -ms-overflow-style: none;  /* IE and Edge */\n    scrollbar-width: none;  /* Firefox */\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop_layout": "lJ_Fj_pDcHuYeznTyyKG",
	"desktop_layout__sidebar": "huB768xyH3TpYbIz3KXR",
	"desktop_layout__main": "TA0gxRj4hpKrcJFx4C_3"
};
export default ___CSS_LOADER_EXPORT___;

import React, { ReactElement } from 'react';

import { Icon } from '@iconify/react';
import { cloneDeep } from 'lodash';
import { FieldErrors } from 'react-hook-form';
import * as yup from 'yup';

import { FORM_FIELDS, FormDataType, UiFieldType } from '~/types/form';

export const addTrimValidationToScheme = (
  // TODO in future backend should modify all needed json_schemes in DB (pattern: '\\S')
  validationSchemaToModify: Record<string, any>,
  uiSchema: Record<string, UiFieldType>,
): Record<string, any> => {
  if (
    validationSchemaToModify.properties &&
    validationSchemaToModify.required.length
  ) {
    const textFields = Object.entries(uiSchema)
      .filter(
        ([key, fieldConfig]) =>
          [
            FORM_FIELDS.TEXT,
            FORM_FIELDS.TEXTAREA,
            FORM_FIELDS.TEXTAREA_UPLOAD,
          ].includes(fieldConfig.type) &&
          validationSchemaToModify.required.includes(key),
      )
      .map(([fieldName]) => fieldName);

    if (textFields.length) {
      const clonedValidationSchema = cloneDeep(validationSchemaToModify);
      textFields.forEach((fieldName) => {
        if (clonedValidationSchema.properties[fieldName]) {
          clonedValidationSchema.properties[fieldName].pattern = '\\S';
        }
      });
      return clonedValidationSchema;
    }
  }

  return validationSchemaToModify;
};

export const wrapWithErrorMarkup = (
  text: string | ReactElement,
): JSX.Element => {
  return (
    <div style={{ display: 'flex', gap: '6px' }}>
      <Icon icon="material-symbols:error-outline" width="18" />
      <span style={{ fontWeight: 400, fontSize: '12px', lineHeight: '16px' }}>
        {text}
      </span>
    </div>
  );
};

export const updateErrorMessages = (
  errors: FieldErrors<yup.InferType<any>>,
  validationSchema: Record<string, any>,
  currentValues: FormDataType,
): FieldErrors<yup.InferType<any>> => {
  // @ts-ignore
  return Object.fromEntries(
    // @ts-ignore
    Object.entries(errors).map(([key, value]: [string, { type: string }]) => {
      if (['required'].includes(value.type)) {
        return [
          key,
          {
            ...value,
            message: wrapWithErrorMarkup('Required field'),
          },
        ];
      }

      if (['matches'].includes(value.type)) {
        return [
          key,
          {
            ...value,
            message: wrapWithErrorMarkup(
              errors[key]?.message || 'Incorrect format',
            ),
          },
        ];
      }

      if (['max', 'min'].includes(value.type)) {
        const flatProperties = {
          ...validationSchema.properties,
          ...validationSchema?.allOf?.reduce(
            (acc, curr) => ({ ...acc, ...curr.then.properties }),
            {},
          ),
        };

        const minLength = flatProperties[key]?.minLength || 0;
        const maxLength = flatProperties[key]?.maxLength || 0;
        const currentValueLength = (currentValues[key] as string).length;
        const currentMinRange =
          typeof currentValues[key] === 'string'
            ? `(${currentValueLength}/${minLength})`
            : '';
        const currentMaxRange =
          typeof currentValues[key] === 'string'
            ? `(${currentValueLength}/${maxLength})`
            : '';

        return [
          key,
          {
            ...value,
            message: wrapWithErrorMarkup(
              value.type === 'max'
                ? `Must be at most ${maxLength} characters ${currentMaxRange}`
                : `Must be at least ${minLength} characters ${currentMinRange}`,
            ),
          },
        ];
      }

      return [key, value];
    }),
  );
};

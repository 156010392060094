// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WxVqNJV63NtYx7cAKjzL{display:flex;flex-direction:column;height:100%;overflow:hidden}.CM9CuNAqe6dnorDTUCL9{display:flex;flex-direction:column;flex:1;width:100%;padding:32px;overflow:auto}.TUXEMnnxbKNNowpViVBf{display:flex;align-items:center;gap:8px}", "",{"version":3,"sources":["webpack://./src/modules/products/views/ProductNew/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAIA,sBCHE,YADiB,CASf,qBDJwB,CAC1B,WAAA,CACA,eAAA,CAGF,sBCTE,YADiB,CASf,qBDEwB,CAC1B,MAAA,CACA,UAAA,CACA,YAAA,CACA,aAAA,CAGF,sBCjBE,YADiB,CAGf,kBDgBoB,CACtB,OAAA","sourcesContent":["@import \"src/styles/mixins\";\n@import \"src/styles/colors\";\n@import \"src/styles/breakpoints\";\n\n.container {\n  @include flex($direction: column);\n  height: 100%;\n  overflow: hidden;\n}\n\n.content {\n  @include flex($direction: column);\n  flex: 1;\n  width: 100%;\n  padding: 32px;\n  overflow: auto;\n}\n\n.action_button {\n  @include flex($align: center);\n  gap: 8px;\n}\n","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "WxVqNJV63NtYx7cAKjzL",
	"content": "CM9CuNAqe6dnorDTUCL9",
	"action_button": "TUXEMnnxbKNNowpViVBf"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".my4gjf3_SpLsGtL85aKV{position:relative;display:flex;align-items:center;justify-content:center}.LiqEH0H9OldmzQm4Atjo{position:absolute;z-index:2;display:block;width:100%;height:100%}.XbHoME1AY6nD9r6VegJe{display:block;width:100%;height:100%;filter:blur(4px)}.XbHoME1AY6nD9r6VegJe.JFrJUlVHQ8F_Hf_08dLc{visibility:hidden}", "",{"version":3,"sources":["webpack://./src/components/atoms/Image/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAKE,sBACE,iBAAA,CCLF,YADiB,CAGf,kBDIsB,CCDtB,sBDCwC,CAG1C,sBACE,iBAAA,CACA,SAAA,CACA,aAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CAEA,2CACE,iBAAA","sourcesContent":["@import \"src/styles/breakpoints\";\n@import \"src/styles/mixins\";\n\n\n.image {\n  &__wrapper {\n    position: relative;\n    @include flex($align: center, $justify: center);\n  }\n\n  &__main {\n    position: absolute;\n    z-index: 2;\n    display: block;\n    width: 100%;\n    height: 100%;\n  }\n\n  &__preview {\n    display: block;\n    width: 100%;\n    height: 100%;\n    filter: blur(4px);\n\n    &.is_hidden {\n      visibility: hidden;\n    }\n  }\n}","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image__wrapper": "my4gjf3_SpLsGtL85aKV",
	"image__main": "LiqEH0H9OldmzQm4Atjo",
	"image__preview": "XbHoME1AY6nD9r6VegJe",
	"is_hidden": "JFrJUlVHQ8F_Hf_08dLc"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OoLlzkuoEz6G4eWy2QYp{height:100%;background-color:#dbe1ff;overflow-y:scroll}.OoLlzkuoEz6G4eWy2QYp::-webkit-scrollbar{display:none}.w5lNZ98rrEBP9yWzVrUM{display:flex;flex-direction:column}.z_rStniWAv3GuVdW7fYZ{padding:16px 24px 32px}.aka6Wx7kot2HTAzDcDrB{display:block;margin:20px auto;border:1px solid #eef0f5;width:calc(100% - 20px)}", "",{"version":3,"sources":["webpack://./src/components/layoutComponents/SidebarLayout/Sidebar/styles.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,wBCKW,CDJX,iBAAA,CAEA,yCACE,YAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,sBAAA,CAGF,sBACE,aAAA,CACA,gBAAA,CACA,wBAAA,CACA,uBAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.sidebar {\n  height: 100%;\n  background-color: $primary-80;\n  overflow-y: scroll;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n\n  &__items {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__logo_container {\n    padding: 16px 24px 32px;\n  }\n\n  &__divider {\n    display: block;\n    margin: 20px auto;\n    border: 1px solid $neutral-80;\n    width: calc(100% - 20px);\n  }\n}\n","$black: #000000;\r\n$white: #FFFFFF;\r\n\r\n$primary-20: #00164F;\r\n$primary-30: #13307E;\r\n$primary-35: #2F4990;\r\n$primary-40: #3B5AAE;\r\n$primary-50: #708DE4;\r\n$primary-60: #A5B8F4;\r\n$primary-80: #DBE1FF;\r\n$primary-90: #EBEEFF;\r\n\r\n$gradient-40: linear-gradient(90deg, #00B39B, #3B71F7);\r\n\r\n$error-20: #410001;\r\n$error-30: #7D0008;\r\n$error-40: #BF3C32;\r\n$error-50: #FF8A7C;\r\n$error-60: #DF847D;\r\n$error-80: #FFDAD5;\r\n\r\n$neutral-20: #46474A;\r\n$neutral-30: #76777A;\r\n$neutral-40: #AEAFB1;\r\n$neutral-60: #DBDCDF;\r\n$neutral-80: #EEF0F5;\r\n\r\n$warning-40: #F9E0A2;\r\n$warning-50: #F5D174;\r\n$warning-60: #EFB217;\r\n$warning-80: #FFEFC8;\r\n$warning-90: #FFF8E5;\r\n\r\n$green-30: #006B5D;\r\n$green-50: #00B39B;\r\n$green-70: #80D9CD;\r\n$green-90: #CCF0EB;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "OoLlzkuoEz6G4eWy2QYp",
	"sidebar__items": "w5lNZ98rrEBP9yWzVrUM",
	"sidebar__logo_container": "z_rStniWAv3GuVdW7fYZ",
	"sidebar__divider": "aka6Wx7kot2HTAzDcDrB"
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

import Api from '~/Api';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { ProductListRoute } from '~/modules/products';
import OverviewForm from '~/modules/products/components/OverviewForm';
import { ProductType } from '~/modules/products/types';

import styles from './styles.module.scss';

const prepareProductData = (data: ProductType): ProductType => {
  return {
    ...data,
    // These fields are skipped at Form yet should be passed to match ProductType
    words_amount: data.words_amount || 1000000,
    trial_words_amount: data.trial_words_amount || 1000000,
    discount_percentage_ui: data.discount_percentage_ui || 0,
    details_ui: data.details_ui || '',
    is_popular_ui: data.is_popular_ui || false,
    regularity_ui: data.regularity_ui || data.regularity || '',
    new_price_ui: data.new_price_ui || data.price || 0,
    old_price_ui: data.old_price_ui || 0,
    is_unlimited: data.is_unlimited || false,
    bi_is_upsell: data.bi_is_upsell || false,
    tags: data.tags || [],
  };
};

const ProductNew: FC = () => {
  const { showSnackbar } = useSnackBarContext();

  const navigate = useNavigate();

  const handleProductCreate = async (data: ProductType): Promise<void> => {
    try {
      await Api.createProduct(prepareProductData(data));
      showSnackbar('Product Created', 'success');
    } catch (e: any) {
      const errorMessage = e.message || e.error || 'Error';
      showSnackbar(errorMessage);
    }
  };

  return (
    <div className={styles.container}>
      <TopHeadlineRow
        text="Create New Product"
        onBack={(): void => {
          navigate(ProductListRoute.path);
        }}
      />

      <div className={styles.content}>
        <OverviewForm
          currentProduct={null}
          onSubmit={handleProductCreate}
          submitButtonText={
            <div className={styles.action_button}>
              <Icon icon="gridicons:create" width="20" />
              <span>Create</span>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ProductNew;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V7GCDv9TSMUQZIZK3z2w{margin-bottom:32px}.baMuEVrD31jnAO3Y2fxg{font-size:18px;font-weight:700;margin-bottom:24px}.BJ3a70DGgGxIdd62w_5g{display:flex;align-items:center;gap:12px}.mCcyYIAMekOiLJORocHn{width:120px}", "",{"version":3,"sources":["webpack://./src/components/modals/DeleteConfirmationModal/styles.module.scss","webpack://./src/styles/_mixins.scss"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBCXE,YADiB,CAGf,kBDUoB,CACtB,QAAA,CAGF,sBACE,WAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.image {\n  margin-bottom: 32px;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 700;\n  margin-bottom: 24px;\n}\n\n.buttons_container {\n  @include flex($align: center);\n  gap: 12px;\n}\n\n.button {\n  width: 120px;\n}","@mixin flex($type: flex, $align: false, $justify: false, $direction: false) {\n  display: $type;\n  @if ($align) {\n    align-items: $align;\n  }\n  @if ($justify) {\n    justify-content: $justify;\n  }\n  @if ($direction) {\n    flex-direction: $direction;\n  }\n}\n\n@mixin square($size) {\n  width: $size;\n  height: $size;\n}\n\n@mixin circle($size) {\n  width: $size;\n  height: $size;\n  border-radius: 50%;\n}\n\n@mixin backgroundClippedText($background) {\n  background: $background;\n  -webkit-background-clip: text;\n  background-clip: text;\n  background-size: cover;\n  color: transparent;\n}\n\n@mixin ignoreParentsWidth() {\n  width: 100vw;\n  margin-left: calc(50% - 50vw);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "V7GCDv9TSMUQZIZK3z2w",
	"title": "baMuEVrD31jnAO3Y2fxg",
	"buttons_container": "BJ3a70DGgGxIdd62w_5g",
	"button": "mCcyYIAMekOiLJORocHn"
};
export default ___CSS_LOADER_EXPORT___;

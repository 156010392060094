import React, { FC, ReactElement } from 'react';

import { MenuItem, MenuItemProps, styled } from '@mui/material';

import {
  StyledTextField,
  TextFieldCustomProps,
} from '~/components/atoms/inputs/TextField';
import { colors } from '~/theme/colors';

const StyledMenuItem = styled(MenuItem)<
  MenuItemProps & { size?: 'small' | 'medium' | 'large' }
>(({ size = 'medium' }) => ({
  overflow: 'hidden',
  display: 'flex',
  padding: size === 'small' ? '8px 12px' : '14px 12px',
  gap: '8px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  cursor: 'pointer',

  '&:hover': {
    background: 'rgba(29, 27, 32, 0.08)',
  },
}));

export type DropdownProps = TextFieldCustomProps & {
  options: {
    value: string | number | boolean;
    text: string | ReactElement;
    disabled?: boolean;
    tooltip?: string | ReactElement;
  }[];
};

const Dropdown: FC<DropdownProps> = ({ options, ...props }) => {
  return options.length && props.value !== undefined && props.value !== null ? (
    <>
      <StyledTextField
        select
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                marginLeft: 0,
                borderRadius: '8px',
                padding: 0,
                backgroundColor: colors.neutral_80,
                maxHeight: '300px',
                overflowY: 'auto',
              },
              sx: {
                '.MuiList-root': {
                  padding: 0,
                },
                '&::-webkit-scrollbar-track': {
                  background: 'none',
                },

                '&::-webkit-scrollbar': {
                  width: '8px',
                },

                '&::-webkit-scrollbar-thumb': {
                  background: '#D9DADF',
                  'border-radius': '4px',
                },
              },
            },
          },
        }}
        {...props}
      >
        {options.map((item) => (
          <StyledMenuItem
            key={String(item.value)}
            // @ts-ignore
            value={item.value}
            size={props.size || 'medium'}
          >
            {item.text}
          </StyledMenuItem>
        ))}
      </StyledTextField>
    </>
  ) : (
    <StyledTextField disabled {...props} />
  );
};

export default Dropdown;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export type CoreAccess = Record<string, boolean>;

export interface CoreAdmin {
  created_at?: string;
  email?: string;
  id?: string;
  permissions?: CorePermissions;
  role?: CoreAdminRole;
  status?: CoreStatus;
  updated_at?: string;
}

export enum CoreAdminRole {
  SuperAdmin = 'super_admin',
  Support = 'support_agent',
}

export interface CoreOrder {
  amount?: string;
  name?: string;
  processed_at?: string;
}

export type CorePermissions = Record<string, CoreAccess>;

export interface CoreProduct {
  alternative_non_trial_name?: string;
  bi_is_upsell?: boolean;
  billing_period?: number;
  created_at?: string;
  currency?: string;
  description?: string;
  description_ui?: string;
  details_ui?: string;
  discount_percentage_ui?: number;
  has_trial_period?: boolean;
  id?: string;
  is_popular_ui?: boolean;
  is_solid?: boolean;
  is_unlimited?: boolean;
  name?: string;
  new_price_ui?: number;
  old_price_ui?: number;
  price?: number;
  regularity?: ModelsRegularity;
  regularity_ui?: ModelsRegularity;
  solid_name_for_bi?: string;
  tags?: string[];
  trial_amount?: number;
  trial_period?: number;
  trial_words_amount?: number;
  updated_at?: string;
  words_amount?: number;
}

export enum CoreStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type FrontdefABTestsPresenter = Record<string, string>;

export interface FrontdefProduct {
  alternative_non_trial_name?: string;
  bi_is_upsell?: boolean;
  billing_period?: number;
  created_at?: string;
  currency?: string;
  description?: string;
  description_ui?: string;
  details_ui?: string;
  discount_percentage_ui?: number;
  has_trial_period?: boolean;
  id?: string;
  is_popular_ui?: boolean;
  is_solid?: boolean;
  is_unlimited?: boolean;
  name?: string;
  new_price_ui?: number;
  old_price_ui?: number;
  price?: number;
  regularity?: FrontdefRegularity;
  regularity_ui?: string;
  solid_name_for_bi?: string;
  tags?: string[];
  trial_amount?: number;
  trial_period?: number;
  trial_words_amount?: number;
  updated_at?: string;
  words_amount?: number;
}

export enum FrontdefRegularity {
  RegularityDaily = 'day',
  RegularityMonthly = 'month',
  RegularityYearly = 'year',
  RegularityWeekly = 'week',
  RegularityOnetime = 'onetime',
}

export enum FrontdefSubscriptionStatus {
  SubscriptionStatusActive = 'active',
  SubscriptionStatusCancelled = 'cancelled',
  SubscriptionStatusPaused = 'paused',
  SubscriptionStatusPausePending = 'pause_pending',
  SubscriptionStatusRedemption = 'redemption',
  SubscriptionStatusCancelPending = 'cancel_pending',
  SubscriptionStatusRedemtionPaymentPending = 'redemption_payment_pending',
  SubscriptionStatusRedemptionPaymentFailed = 'redemption_payment_failed',
}

export interface FrontdefUserOnboardingV2 {
  ai_experience?: string;
  company_size?: string;
  created_at?: string;
  education_level?: string;
  field_of_study?: string;
  is_finished?: boolean;
  language?: string;
  position?: string;
  profession?: string;
  purposes?: string;
  updated_at?: string;
  user_id?: string;
}

export interface FrontdefUserReactions {
  rate?: {
    '1'?: number;
    '2'?: number;
    '3'?: number;
    '4'?: number;
    '5'?: number;
  };
  response?: {
    '1'?: number;
    '-1'?: number;
  };
}

export interface FrontdefUserSubscriptionPresenter {
  billing_period?: string;
  cancel_code?: string;
  cancel_for_change_subs?: boolean;
  cancel_for_change_subs_to_product_id?: string;
  cancel_message?: string;
  cancel_request_at?: string;
  cancelled_at?: string;
  created_at?: string;
  expired_at?: string;
  id?: string;
  is_current?: boolean;
  is_trial?: boolean;
  is_unlimited?: boolean;
  next_charge_at?: string;
  pause_from?: string;
  pause_to?: string;
  payment_type?: string;
  product_id?: string;
  response_count_total?: number;
  saved_time_minutes?: number;
  solid_event_id?: string;
  started_at?: string;
  status?: FrontdefSubscriptionStatus;
  subscription_name?: string;
  subscription_price?: number;
  updated_at?: string;
  user_id?: string;
  words_amount_left?: number;
  /** NON DB FIELDS */
  words_amount_total?: number;
  words_generated_total?: number;
}

export interface GithubComAppsciJustdoneAiAdminCoreUser {
  ab_tests?: FrontdefABTestsPresenter;
  avatar_url?: string;
  created_at?: string;
  detectors_extra_words_left?: number;
  email?: string;
  extra_fields?: number[];
  gclid?: string;
  id?: string;
  interaction_data_count?: FrontdefUserReactions;
  is_chrome_extension_installed?: boolean;
  is_onboarded?: boolean;
  is_stripe_user?: boolean;
  is_test_user?: boolean;
  msclkid?: string;
  name?: string;
  orders?: CoreOrder[];
  product_id?: string;
  product_name?: string;
  /** NON DB FIELDS */
  response_count_total?: number;
  status?: string;
  subscription_status?: FrontdefSubscriptionStatus;
  updated_at?: string;
  user_features?: {
    detectors_enabled?: boolean;
  };
  user_onboarding?: FrontdefUserOnboardingV2;
  user_product?: FrontdefProduct;
  user_subscription?: FrontdefUserSubscriptionPresenter;
  was_in_redemption?: boolean;
  words_amount_left?: number;
}

export interface GithubComAppsciJustdoneAiAdminPresenterHttpHandlersGetProfileResponse {
  created_at?: string;
  email?: string;
  id?: string;
  permissions?: CorePermissions;
  role?: CoreAdminRole;
  status?: CoreStatus;
  updated_at?: string;
}

export interface GithubComAppsciJustdoneAiAdminPresenterHttpHandlersLoginRequest {
  email?: string;
  password?: string;
}

export interface GithubComAppsciJustdoneAiAdminPresenterHttpHandlersLoginResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface GithubComAppsciJustdoneAiAdminPresenterHttpHandlersRefreshTokenRequest {
  refresh_token?: string;
}

export interface GithubComAppsciJustdoneAiAdminPresenterHttpHandlersRefreshTokenResponse {
  access_token?: string;
  refresh_token?: string;
}

export interface HandlersCreateAdminRequest {
  email: string;
  password: string;
  role: 'support_agent';
}

export interface HandlersCreateProductRequest {
  alternative_non_trial_name: string;
  bi_is_upsell: boolean;
  /** Sub Duration */
  billing_period: number;
  /** Default Currency */
  currency: string;
  /** Internal Description */
  description: string;
  /** Public Description */
  description_ui: string;
  details_ui: string;
  discount_percentage_ui: number;
  /** Has Trial */
  has_trial_period: boolean;
  /**
   * Plan ID
   * As string for better error reporting with go-validate
   */
  id: string;
  is_popular_ui: boolean;
  is_solid: boolean;
  is_unlimited: boolean;
  /** Internal Plan Name */
  name: string;
  new_price_ui: number;
  old_price_ui: number;
  /** Sub Price */
  price: number;
  /** Sub Regularity */
  regularity: 'day' | 'month' | 'year' | 'week' | 'onetime';
  regularity_ui?: 'day' | 'month' | 'year' | 'week' | 'onetime';
  /** Plan Name */
  solid_name_for_bi: string;
  tags: string[];
  /** Trial Price */
  trial_amount: number;
  /** Trial Duration */
  trial_period: number;
  trial_words_amount: number;
  words_amount: number;
}

export interface HandlersGetAdminResponse {
  created_at?: string;
  email?: string;
  id?: string;
  permissions?: CorePermissions;
  role?: CoreAdminRole;
  status?: CoreStatus;
  updated_at?: string;
}

export interface HandlersGetProductResponse {
  alternative_non_trial_name?: string;
  bi_is_upsell?: boolean;
  billing_period?: number;
  created_at?: string;
  currency?: string;
  description?: string;
  description_ui?: string;
  details_ui?: string;
  discount_percentage_ui?: number;
  has_trial_period?: boolean;
  id?: string;
  is_popular_ui?: boolean;
  is_solid?: boolean;
  is_unlimited?: boolean;
  name?: string;
  new_price_ui?: number;
  old_price_ui?: number;
  price?: number;
  regularity?: ModelsRegularity;
  regularity_ui?: ModelsRegularity;
  solid_name_for_bi?: string;
  tags?: string[];
  trial_amount?: number;
  trial_period?: number;
  trial_words_amount?: number;
  updated_at?: string;
  words_amount?: number;
}

export interface HandlersGetUserResponse {
  ab_tests?: FrontdefABTestsPresenter;
  avatar_url?: string;
  created_at?: string;
  detectors_extra_words_left?: number;
  email?: string;
  extra_fields?: number[];
  gclid?: string;
  id?: string;
  interaction_data_count?: FrontdefUserReactions;
  is_chrome_extension_installed?: boolean;
  is_onboarded?: boolean;
  is_stripe_user?: boolean;
  is_test_user?: boolean;
  msclkid?: string;
  name?: string;
  orders?: CoreOrder[];
  product_id?: string;
  product_name?: string;
  /** NON DB FIELDS */
  response_count_total?: number;
  status?: string;
  subscription_status?: FrontdefSubscriptionStatus;
  updated_at?: string;
  user_features?: {
    detectors_enabled?: boolean;
  };
  user_onboarding?: FrontdefUserOnboardingV2;
  user_product?: FrontdefProduct;
  user_subscription?: FrontdefUserSubscriptionPresenter;
  was_in_redemption?: boolean;
  words_amount_left?: number;
}

export interface HandlersReplaceProductRequest {
  alternative_non_trial_name: string;
  bi_is_upsell: boolean;
  /** Sub Duration */
  billing_period: number;
  /** Default Currency */
  currency: string;
  /** Internal Description */
  description: string;
  /** Public Description */
  description_ui: string;
  details_ui: string;
  discount_percentage_ui: number;
  /** Has Trial */
  has_trial_period: boolean;
  /**
   * Plan ID
   * As string for better error reporting with go-validate
   */
  id: string;
  is_popular_ui: boolean;
  is_solid: boolean;
  is_unlimited: boolean;
  /** Internal Plan Name */
  name: string;
  new_price_ui: number;
  old_price_ui: number;
  /** Sub Price */
  price: number;
  /** Sub Regularity */
  regularity: 'day' | 'month' | 'year' | 'week' | 'onetime';
  regularity_ui?: 'day' | 'month' | 'year' | 'week' | 'onetime';
  /** Plan Name */
  solid_name_for_bi: string;
  tags: string[];
  /** Trial Price */
  trial_amount: number;
  /** Trial Duration */
  trial_period: number;
  trial_words_amount: number;
  words_amount: number;
}

export interface HandlersSetUserDetectorsExtraWordsRequest {
  words: number;
}

export interface HandlersUpdateAdminRequest {
  role?: 'super_admin' | 'support_agent';
  status?: 'active' | 'inactive';
}

export interface HandlersUpdateEmailRequest {
  email: string;
}

export enum ModelsRegularity {
  RegularityDaily = 'day',
  RegularityMonthly = 'month',
  RegularityYearly = 'year',
  RegularityWeekly = 'week',
  RegularityOnetime = 'onetime',
}

export interface ModelsTotal {
  current_page?: number;
  page_size?: number;
  total_count?: number;
  total_pages?: number;
}

export interface RenderHTTPError {
  error?: string;
  fields?: RenderHTTPErrorFields;
}

export type RenderHTTPErrorFields = Record<string, any>;

export interface RenderHTTPResponse {
  data?: any;
  meta?: any;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'https://{host_api}/admin';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title JustDone Admin API
 * @version 1.0
 * @baseUrl https://{host_api}/admin
 * @contact
 *
 * This is a JustDone Admin API.
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * @description Get admins with pagination, sorting
     *
     * @tags admins
     * @name V1AdminsList
     * @summary Get list of admins
     * @request GET:/api/v1/admins
     */
    v1AdminsList: (
      query?: {
        /** Email to search by */
        search_text?: string;
        /** Field to sort by */
        sort_by?: string;
        /** Sort order (asc || desc) */
        sort_type?: string;
        /** Page number */
        page?: number;
        /** Number of items per page */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: CoreAdmin[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/admins`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new admin with searchText, password, and role
     *
     * @tags admins
     * @name V1AdminsCreate
     * @summary Create a new admin
     * @request POST:/api/v1/admins
     */
    v1AdminsCreate: (
      admin: HandlersCreateAdminRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/admins`,
        method: 'POST',
        body: admin,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Delete an admin by their ID
     *
     * @tags admins
     * @name V1AdminsDelete
     * @summary Delete admin by id
     * @request DELETE:/api/v1/admins/{id}
     */
    v1AdminsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/admins/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get admin by id
     *
     * @tags admins
     * @name V1AdminsDetail
     * @summary Get admin by id
     * @request GET:/api/v1/admins/{id}
     */
    v1AdminsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersGetAdminResponse;
        },
        any
      >({
        path: `/api/v1/admins/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update an admin by their ID
     *
     * @tags admins
     * @name V1AdminsPartialUpdate
     * @summary Update admin by id
     * @request PATCH:/api/v1/admins/{id}
     */
    v1AdminsPartialUpdate: (
      id: string,
      admin: HandlersUpdateAdminRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/v1/admins/${id}`,
        method: 'PATCH',
        body: admin,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description User Login
     *
     * @tags auth
     * @name V1AuthLoginCreate
     * @summary Login
     * @request POST:/api/v1/auth/login
     */
    v1AuthLoginCreate: (
      body: GithubComAppsciJustdoneAiAdminPresenterHttpHandlersLoginRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: GithubComAppsciJustdoneAiAdminPresenterHttpHandlersLoginResponse;
        },
        any
      >({
        path: `/api/v1/auth/login`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Refresh token
     *
     * @tags auth
     * @name V1AuthRefreshTokenCreate
     * @summary Refresh token
     * @request POST:/api/v1/auth/refresh-token
     */
    v1AuthRefreshTokenCreate: (
      body: GithubComAppsciJustdoneAiAdminPresenterHttpHandlersRefreshTokenRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: GithubComAppsciJustdoneAiAdminPresenterHttpHandlersRefreshTokenResponse;
        },
        any
      >({
        path: `/api/v1/auth/refresh-token`,
        method: 'POST',
        body: body,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get products with pagination, sorting, filters
     *
     * @tags products
     * @name V1ProductsList
     * @summary Get page of products
     * @request GET:/api/v1/products
     */
    v1ProductsList: (
      query?: {
        /** ID or Name to search by */
        search_text?: string;
        /** Field to sort by */
        sort_by?: string;
        /** Sort order (asc || desc) */
        sort_type?: string;
        /** Page number */
        page?: number;
        /** Number of items per page */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: CoreProduct[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/products`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new product
     *
     * @tags products
     * @name V1ProductsCreate
     * @summary Create a new product
     * @request POST:/api/v1/products
     */
    v1ProductsCreate: (
      product: HandlersCreateProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: CoreProduct;
        },
        RenderHTTPError
      >({
        path: `/api/v1/products`,
        method: 'POST',
        body: product,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Replace an existing product by ID
     *
     * @tags products
     * @name V1ProductsUpdate
     * @summary Replace an existing product
     * @request PUT:/api/v1/products
     */
    v1ProductsUpdate: (
      product: HandlersReplaceProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: CoreProduct;
        },
        RenderHTTPError
      >({
        path: `/api/v1/products`,
        method: 'PUT',
        body: product,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete a product from our database by ID
     *
     * @tags products
     * @name V1ProductsDelete
     * @summary Delete a product
     * @request DELETE:/api/v1/products/{id}
     */
    v1ProductsDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/products/${id}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Get product by id
     *
     * @tags products
     * @name V1ProductsDetail
     * @summary Get product by id
     * @request GET:/api/v1/products/{id}
     */
    v1ProductsDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersGetProductResponse;
        },
        any
      >({
        path: `/api/v1/products/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get profile
     *
     * @tags profile
     * @name V1ProfileList
     * @summary Get profile
     * @request GET:/api/v1/profile
     */
    v1ProfileList: (params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: GithubComAppsciJustdoneAiAdminPresenterHttpHandlersGetProfileResponse;
        },
        any
      >({
        path: `/api/v1/profile`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Get users with pagination, sorting, filters
     *
     * @tags users
     * @name V1UsersList
     * @summary Get page of users
     * @request GET:/api/v1/users
     */
    v1UsersList: (
      query?: {
        /** ID or Email to search by */
        search_text?: string;
        /** Field to sort by */
        sort_by?: string;
        /** Sort order (asc || desc) */
        sort_type?: string;
        /** Page number */
        page?: number;
        /** Number of items per page */
        per_page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: GithubComAppsciJustdoneAiAdminCoreUser[];
          meta?: ModelsTotal;
        },
        any
      >({
        path: `/api/v1/users`,
        method: 'GET',
        query: query,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Delete user by id
     *
     * @tags users
     * @name V1UsersDelete
     * @summary Delete user by id
     * @request DELETE:/api/v1/users/{id}
     */
    v1UsersDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/v1/users/${id}`,
        method: 'DELETE',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user by id
     *
     * @tags users
     * @name V1UsersDetail
     * @summary Get user by id
     * @request GET:/api/v1/users/{id}
     */
    v1UsersDetail: (id: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: HandlersGetUserResponse;
        },
        any
      >({
        path: `/api/v1/users/${id}`,
        method: 'GET',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Change extra words amount of user
     *
     * @tags users
     * @name V1UsersDetectorsExtraWordsPartialUpdate
     * @summary Change extra words amount of user
     * @request PATCH:/api/v1/users/{id}/detectors-extra-words
     */
    v1UsersDetectorsExtraWordsPartialUpdate: (
      id: string,
      words: HandlersSetUserDetectorsExtraWordsRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: number;
        },
        any
      >({
        path: `/api/v1/users/${id}/detectors-extra-words`,
        method: 'PATCH',
        body: words,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update user email
     *
     * @tags users
     * @name V1UsersEmailPartialUpdate
     * @summary Update user email
     * @request PATCH:/api/v1/users/{id}/email
     */
    v1UsersEmailPartialUpdate: (
      id: string,
      request: HandlersUpdateEmailRequest,
      params: RequestParams = {},
    ) =>
      this.request<
        RenderHTTPResponse & {
          data?: string;
        },
        any
      >({
        path: `/api/v1/users/${id}/email`,
        method: 'PATCH',
        body: request,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Update user password
     *
     * @tags users
     * @name V1UsersPasswordPartialUpdate
     * @summary Update user password with random password
     * @request PATCH:/api/v1/users/{id}/password
     */
    v1UsersPasswordPartialUpdate: (id: string, params: RequestParams = {}) =>
      this.request<
        RenderHTTPResponse & {
          data?: string;
        },
        any
      >({
        path: `/api/v1/users/${id}/password`,
        method: 'PATCH',
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}

import React, { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typography } from '@mui/material';
import convertToYup from 'json-schema-yup-transformer';
import { FormProvider, useForm } from 'react-hook-form';

import Button from '~/components/atoms/buttons/Button';
import InputRenderer from '~/modules/products/components/OverviewForm/InputRenderer';
import { PRODUCT_SCHEMA } from '~/modules/products/constants';
import { ProductType } from '~/modules/products/types';
import { Typographies } from '~/theme/typography';
import { FORM_FIELDS, FormDataType, UiFieldType } from '~/types/form';
import { resetFormFields } from '~/utils/form';
import { updateErrorMessages } from '~/utils/validation';

import styles from './styles.module.scss';

const hasTrialFieldName = 'has_trial_period';

const convertedUiValue = (value: unknown): string | number => {
  const convertedValue = JSON.stringify(value, null, 2);

  if (value === '') {
    return '-';
  }

  if (typeof value !== 'string') {
    return convertedValue;
  }

  return !isNaN(Number(value)) ? Number(value) : convertedValue;
};

type Props = {
  currentProduct: ProductType | null;
  onSubmit: (data: ProductType) => Promise<void>;
  submitButtonText?: JSX.Element;
};

const OverviewForm: FC<Props> = ({
  currentProduct,
  onSubmit,
  submitButtonText = <>Submit</>,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<FormDataType>({
    resolver: async (values, context, options) => {
      const primaryValidation = await yupResolver(
        convertToYup(PRODUCT_SCHEMA.json_schema as Record<string, any>),
      )(values, context, options);

      return {
        ...primaryValidation,
        errors: updateErrorMessages(
          primaryValidation.errors,
          PRODUCT_SCHEMA.json_schema,
          values,
        ),
      };
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { handleSubmit, setValue, watch, resetField } = form;

  const handleSuccessSubmit = async (data: FormDataType): Promise<void> => {
    try {
      setIsLoading(true);
      const transformedData = Object.keys(data).reduce((acc, key) => {
        const value = data[key];
        const schemaField = PRODUCT_SCHEMA.ui_schema[key];

        // Convert fields like price, billing_period, trial_period, etc (number fields)
        if (
          schemaField?.type === FORM_FIELDS.NUMBER &&
          typeof value === 'string' &&
          !isNaN(Number(value))
        ) {
          acc[key] = Number(value);
        } else {
          acc[key] = value;
        }
        return acc;
      }, {} as FormDataType);

      await onSubmit(transformedData as ProductType);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const getVisibleFields = (): { name: string; value: UiFieldType }[] => {
    return Object.entries(PRODUCT_SCHEMA.ui_schema).reduce(
      (acc, [name, value]) => {
        const fieldSchema = PRODUCT_SCHEMA.ui_schema[name];

        if (fieldSchema.$ref) {
          const shouldAdd = fieldSchema.$ref.some((ref) => {
            const dependency = ref.field;
            const requiredValue = ref.value;
            return form.watch(dependency) === requiredValue;
          });

          if (!shouldAdd) {
            return acc;
          }
        }

        if (fieldSchema.props.hidden) {
          return acc;
        }

        return [...acc, { name, value }];
      },
      [] as { name: string; value: UiFieldType }[],
    );
  };

  const visibleFields = getVisibleFields();

  const formValues = watch();

  useEffect(() => {
    resetFormFields(
      PRODUCT_SCHEMA.ui_schema,
      setValue,
      currentProduct ? currentProduct : {},
    );
  }, [PRODUCT_SCHEMA.ui_schema, currentProduct]);

  useEffect(() => {
    if (
      typeof formValues[hasTrialFieldName] === 'boolean' &&
      !formValues[hasTrialFieldName]
    ) {
      const dependentFields = Object.entries(PRODUCT_SCHEMA.ui_schema).reduce(
        (acc, [key, value]) => {
          if (value.$ref) {
            value.$ref.forEach((ref) => {
              if (ref.field === hasTrialFieldName) {
                acc.push(key);
              }
            });
          }
          return acc;
        },
        [] as string[],
      );

      dependentFields.forEach((field) => {
        resetField(field, { defaultValue: '' });
      });
    }
  }, [formValues[hasTrialFieldName]]);

  return (
    <div className={styles.container}>
      <div className={styles.form__outer_container}>
        <FormProvider {...form}>
          <form className={styles.form__inner_container}>
            <div className={styles.form__fieldset}>
              {visibleFields.map(({ name, value }) => {
                return (
                  <div className={styles.form__field} key={name}>
                    {value.group && (
                      <Typography
                        className={styles.form__group}
                        variant={Typographies.BODY_MEDIUM}
                        component="div"
                      >
                        <span>{value.group}</span>
                      </Typography>
                    )}

                    <InputRenderer
                      type={value.type}
                      props={{
                        ...value.props,
                        name,
                        label: value.title,
                        fullWidth: true,
                      }}
                    />

                    {!value.props.required && (
                      <p className={styles.form__optional_label}>Optional</p>
                    )}
                  </div>
                );
              })}
            </div>
          </form>

          <div className={styles.buttons_container}>
            <Button
              type="submit"
              disabled={isLoading}
              color="primary"
              variant="contained"
              onClick={(e): void => {
                handleSubmit((data) => handleSuccessSubmit(data))(e);
              }}
            >
              {submitButtonText}
            </Button>
          </div>
        </FormProvider>
      </div>

      <div className={styles.preview}>
        <Typography
          className={styles.preview__title}
          variant={Typographies.TITLE_LARGE}
          component="div"
        >
          Preview
        </Typography>

        <div className={styles.preview__list}>
          {Object.entries(formValues).map(([key, value]) => {
            const group = PRODUCT_SCHEMA.ui_schema[key].group;

            return (
              <div key={key} className={styles.preview__item}>
                {group && (
                  <Typography
                    className={styles.form__group}
                    variant={Typographies.BODY_MEDIUM}
                    component="div"
                  >
                    <span>{group}</span>
                  </Typography>
                )}

                <Typography variant={Typographies.TITLE_MEDIUM} component="div">
                  <span className={styles.preview__item_key}>
                    {PRODUCT_SCHEMA.ui_schema[key].title}:
                  </span>{' '}
                  <span className={styles.preview__item_value}>
                    {convertedUiValue(value)}
                  </span>
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OverviewForm;

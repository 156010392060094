// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EoyPp0XzPCRRfwrnZQK_{display:block;font-size:15px;font-weight:700;margin-bottom:16px;font-family:\"Source Sans Pro\",sans-serif}", "",{"version":3,"sources":["webpack://./src/components/molecules/TotalCount/styles.module.scss","webpack://./src/styles/_fonts.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,wCCNoB","sourcesContent":["@import \"src/styles/fonts\";\n\n.count {\n  display: block;\n  font-size: 15px;\n  font-weight: 700;\n  margin-bottom: 16px;\n  font-family: $content-font-family;\n}","$headings-font-family: 'Source Serif Pro', sans-serif;\n$content-font-family: 'Source Sans Pro', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"count": "EoyPp0XzPCRRfwrnZQK_"
};
export default ___CSS_LOADER_EXPORT___;

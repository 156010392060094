import React, { FC } from 'react';

import clsx from 'clsx';

import BackButton from '~/components/atoms/buttons/BackButton';

import styles from './styles.module.scss';

type Props = {
  text: string;
  className?: string;
  onBack?: () => void;
};

const TopHeadlineRow: FC<Props> = ({ text, className, onBack, children }) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.left_side}>
        {onBack && (
          <BackButton className={styles.back_button} text="" onClick={onBack} />
        )}
        <div className={styles.text}>{text}</div>
      </div>

      {children && <div>{children}</div>}
    </div>
  );
};

export default TopHeadlineRow;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FZ8tSjujYy0LTrDw1KBi{display:grid;grid-auto-columns:1fr 1fr;grid-gap:8px;grid-template-areas:\"general general\" \"sub sub\";grid-template-rows:min-content 1fr;width:100%;margin-bottom:40px;gap:40px}.KZvM5DkYe4zGUNoiUA_l{grid-area:general}.Ov2v1KKEWMCzzNsmWFhF{grid-area:sub}", "",{"version":3,"sources":["webpack://./src/modules/users/views/UserItem/GeneralInfo/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,yBAAA,CACA,YAAA,CACA,+CAAA,CACA,kCAAA,CACA,UAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,aAAA","sourcesContent":["@import \"src/styles/colors\";\n@import \"src/styles/mixins\";\n\n.container {\n  display: grid;\n  grid-auto-columns: 1fr 1fr;\n  grid-gap: 8px;\n  grid-template-areas: 'general general' 'sub sub';\n  grid-template-rows: min-content 1fr;\n  width: 100%;\n  margin-bottom: 40px;\n  gap: 40px;\n}\n\n.general {\n  grid-area: general;\n}\n\n.sub {\n  grid-area: sub;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "FZ8tSjujYy0LTrDw1KBi",
	"general": "KZvM5DkYe4zGUNoiUA_l",
	"sub": "Ov2v1KKEWMCzzNsmWFhF"
};
export default ___CSS_LOADER_EXPORT___;

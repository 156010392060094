import React, { FC, useEffect, useState } from 'react';

import { Icon } from '@iconify/react';
import { useNavigate, useParams } from 'react-router-dom';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import DeleteConfirmationModal from '~/components/modals/DeleteConfirmationModal';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { READABLE_DATE_TIME_FORMAT } from '~/constants/date';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { ProductListRoute } from '~/modules/products';
import OverviewForm from '~/modules/products/components/OverviewForm';
import { ProductType } from '~/modules/products/types';
import DateService from '~/services/Date';

import styles from './styles.module.scss';

const ProductItem: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<ProductType | null>(null);

  const { showSnackbar } = useSnackBarContext();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const handleProductUpdate = async (data: ProductType): Promise<void> => {
    if (!product) {
      return;
    }

    try {
      setIsLoading(true);
      const updatedProduct = await Api.updateProduct({ ...product, ...data });
      showSnackbar('Product Updated', 'success');
      setProduct(updatedProduct);
    } catch (e: any) {
      showSnackbar(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductDelete = async (): Promise<void> => {
    const onSubmit = async (): Promise<void> => {
      if (!id) {
        return;
      }

      try {
        setIsLoading(true);
        await Api.deleteProduct(id);
        showSnackbar(`Product ${id} is deleted `, 'success');
        setIsLoading(false);
        navigate(ProductListRoute.path);
      } catch (e: any) {
        showSnackbar(e.message);
        setIsLoading(false);
      }
    };

    handleOpenModal({
      component: ({ onClose }) => {
        return (
          <DeleteConfirmationModal
            title="Are you sure you'd like to delete this product?"
            onSubmit={async (): Promise<void> => {
              onClose();
              await onSubmit();
            }}
            onRefuse={onClose}
          />
        );
      },
    });
  };

  useEffect(() => {
    (async (): Promise<void> => {
      if (!id) {
        navigate('/');
        return;
      }

      try {
        setIsLoading(true);

        const currentProduct = await Api.getProduct(id);

        setProduct(currentProduct);
      } catch (e: any) {
        showSnackbar(e.message);
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className={styles.container}>
      <TopHeadlineRow
        text="Product card"
        onBack={(): void => navigate(ProductListRoute.path)}
      >
        <Button
          className={styles.action_button}
          variant="outlined"
          onClick={handleProductDelete}
        >
          <Icon icon="mdi:delete-outline" width="20" />
          <span>Delete product</span>
        </Button>
      </TopHeadlineRow>

      <div className={styles.content}>
        <Loader isLoading={isLoading}>
          <div className={styles.extra_info}>
            <div className={styles.extra_info__title}>
              <div>Created at:</div>
              <div>Updated at:</div>
            </div>
            <div className={styles.extra_info__data}>
              {product?.created_at && (
                <div>
                  {DateService.format(
                    new Date(product.created_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </div>
              )}
              {product?.updated_at && (
                <div>
                  {DateService.format(
                    new Date(product.updated_at),
                    READABLE_DATE_TIME_FORMAT,
                  )}
                </div>
              )}
            </div>
          </div>

          <OverviewForm
            currentProduct={product}
            onSubmit={handleProductUpdate}
            submitButtonText={
              <div className={styles.action_button}>
                <Icon icon="radix-icons:update" width="20" />
                <span>Update</span>
              </div>
            }
          />
        </Loader>
      </div>
    </div>
  );
};

export default ProductItem;

import React from 'react';

import { Icon } from '@iconify/react';

import { PERMISSIONS_KEY } from '~/constants/permissions';
import router, { RouteItemType } from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import {
  AdminByIdPath,
  AdminsPath,
  AdminsSidebarKey,
  AdminsTitle,
} from '~/modules/admins/constants';
import AdminItem from '~/modules/admins/views/AdminItem';
import AdminsList from '~/modules/admins/views/AdminsList';
import { colors } from '~/theme/colors';

const permissionKey = PERMISSIONS_KEY.ADMINS;

export const AdminsListRoute: RouteItemType = {
  path: AdminsPath,
  Component: withSidebarLayout(AdminsList),
  title: AdminsTitle,
  permissionKey,
};

export const AdminsItemRoute: RouteItemType = {
  path: AdminByIdPath,
  Component: withSidebarLayout(AdminItem),
  title: AdminsTitle,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: AdminsPath,
      title: AdminsSidebarKey,
      Icon: ({ isActive }): JSX.Element => (
        <Icon
          icon="ri:admin-fill"
          width="20"
          color={isActive ? colors.white : colors.primary_400}
        />
      ),
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([AdminsListRoute, AdminsItemRoute]);

import React, { FC, useEffect, useMemo, useState } from 'react';

import { Icon } from '@iconify/react';
import { useNavigate, useParams } from 'react-router-dom';

import Api from '~/Api';
import Button from '~/components/atoms/buttons/Button';
import Loader from '~/components/atoms/Loader';
import { useSnackBarContext } from '~/components/layoutComponents/snackbar/SnackbarContext';
import DeleteConfirmationModal from '~/components/modals/DeleteConfirmationModal';
import Tabs from '~/components/molecules/Tabs';
import TopHeadlineRow from '~/components/molecules/TopHeadlineRow';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { UserListRoute } from '~/modules/users';
import { USER_GROUP, UsersPath } from '~/modules/users/constants';
import { ExtendedUserType } from '~/modules/users/types';
import ActionsBlock from '~/modules/users/views/UserItem/ActionsBlock';
import GeneralInfo from '~/modules/users/views/UserItem/GeneralInfo';

import styles from './styles.module.scss';

const UserItem: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<ExtendedUserType | null>(null);
  const [activeTab, setActiveTab] = useState(USER_GROUP.General);

  const { showSnackbar } = useSnackBarContext();
  const { handleOpenModal } = useModalContext();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) {
      navigate('/');
      return;
    }

    (async (id: string): Promise<void> => {
      try {
        setIsLoading(true);
        const user = await Api.getUser(id);
        setUser(user);
      } catch (e: any) {
        showSnackbar(e.message);
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    })(id);
  }, []);

  const handleDeleteUserData = async (): Promise<void> => {
    if (!user) {
      return;
    }

    const onSubmit = async (): Promise<void> => {
      try {
        setIsLoading(true);
        await Api.deleteUser(user.id);
        navigate(UsersPath);
        showSnackbar('Deleted', 'success');
        setIsLoading(false);
      } catch (e: any) {
        showSnackbar(e.message);
        setIsLoading(false);
      }
    };

    handleOpenModal({
      component: ({ onClose }) => {
        return (
          <DeleteConfirmationModal
            title="Are you sure you'd like to delete this user?"
            onSubmit={async (): Promise<void> => {
              onClose();
              await onSubmit();
            }}
            onRefuse={onClose}
          />
        );
      },
    });
  };

  const SCREENS = useMemo(() => {
    if (!user) {
      return;
    }

    return {
      [USER_GROUP.General]: <GeneralInfo user={user} />,
      [USER_GROUP.Actions]: <ActionsBlock user={user} />,
    };
  }, [user]);

  return (
    <div className={styles.container}>
      <Loader isLoading={isLoading} />

      {user && (
        <>
          <TopHeadlineRow
            text="User card"
            onBack={(): void => {
              navigate(UserListRoute.path);
            }}
          >
            <Button
              className={styles.delete_button}
              variant="outlined"
              onClick={handleDeleteUserData}
            >
              <Icon icon="mdi:delete-outline" width="20" />
              <span>Delete user</span>
            </Button>
          </TopHeadlineRow>

          <div className={styles.content}>
            <Tabs
              className={styles.tabs}
              items={Object.values(USER_GROUP).map((el) => {
                return {
                  label: el,
                  isActive: activeTab === el,
                };
              })}
              onClickItem={(idx): void =>
                setActiveTab(Object.values(USER_GROUP)[idx])
              }
            />
            {SCREENS && SCREENS[activeTab]}
          </div>
        </>
      )}
    </div>
  );
};

export default UserItem;

import { PERMISSIONS_KEY } from '~/constants/permissions';
import router, { RouteItemType } from '~/core/router';
import sidebar from '~/core/sidebar';
import { withSidebarLayout } from '~/decorators/withLayout';
import {
  UserByIdPath,
  UsersPath,
  UsersSidebarKey,
  UsersTitle,
} from '~/modules/users/constants';
import UserItem from '~/modules/users/views/UserItem';
import UserList from '~/modules/users/views/UserList';

import { ReactComponent as SidebarIcon } from './assets/sidebar_icon.svg';

const permissionKey = PERMISSIONS_KEY.USERS;

export const UserListRoute: RouteItemType = {
  path: UsersPath,
  Component: withSidebarLayout(UserList),
  title: UsersTitle,
  permissionKey,
};

export const UserItemRoute: RouteItemType = {
  path: UserByIdPath,
  Component: withSidebarLayout(UserItem),
  title: UsersSidebarKey,
  permissionKey,
};

sidebar.addItems({
  b: [
    {
      path: UsersPath,
      title: UsersSidebarKey,
      Icon: SidebarIcon,
      permissionKey,
    },
  ],
});

router.addPrivateRoutes([UserListRoute, UserItemRoute]);

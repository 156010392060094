// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dbJld6WY8wLPGfajXa9e{display:flex;flex-direction:column;height:100vh;overflow:hidden;position:relative}@media(max-width: 719px){.dbJld6WY8wLPGfajXa9e{overflow-x:hidden;overflow-y:auto}}.yiP4A8S8GbgjFJrsYuRH{color:#000 !important}.oFU1mHV6chW0cA6RlAAW{width:206px;height:100%}.s_yNsIrBNmmZxpxZZZ06{flex:1}", "",{"version":3,"sources":["webpack://./src/components/layoutComponents/SidebarLayout/Mobile/styles.module.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CAAA,yBALF,sBAQI,iBAAA,CACA,eAAA,CAAA,CAGF,sBACE,qBAAA,CAGF,sBACE,WAAA,CACA,WAAA,CAGF,sBACE,MAAA","sourcesContent":["@import \"src/styles/colors\";\n@import \"src/styles/breakpoints\";\n\n.mobile_layout {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow: hidden;\n  position: relative;\n\n  @include breakpoint(xs) {\n    overflow-x: hidden;\n    overflow-y: auto;\n  }\n\n  &__burger_button {\n    color: $black !important;\n  }\n\n  &__sidebar {\n    width: 206px;\n    height: 100%;\n  }\n\n  &__main {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile_layout": "dbJld6WY8wLPGfajXa9e",
	"mobile_layout__burger_button": "yiP4A8S8GbgjFJrsYuRH",
	"mobile_layout__sidebar": "oFU1mHV6chW0cA6RlAAW",
	"mobile_layout__main": "s_yNsIrBNmmZxpxZZZ06"
};
export default ___CSS_LOADER_EXPORT___;
